#location-filter-modal + .modal {
  background: none !important;
}

#location-filter-modal + .modal .modal__inner {
  left: 30%;
  height: 435px;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

#location-filter-modal + .modal .modal__content {
  max-height: 300px;
  padding: 0;
}

.modal__footer {
  position: fixed;
  background: #fff;
  border-top: 1px solid #e2e4e8;
}
