.simple-tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 20px;
  border-radius: 10px 10px 0 0;
}

.simple-tabs .radiotab {
  position: absolute;
  opacity: 0;
}

.simple-tabs .label {
  min-width: 10%;
  padding: 16px 16px 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  background: white;
  cursor: pointer;
  position: relative;
  top: 4px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  color: #818996;
  border: none;
}

.simple-tabs .label:hover {
  border-bottom: 4px solid #d22b50;
}

.simple-tabs .label:active {
  background: #fff;
}

.simple-tabs .radiotab:checked + .label {
  background: #fff;
  border-bottom: 4px solid #d22b50;
  color: #344054;
  font-weight: 500;
}

.simple-tabs .panel {
  display: none;
  padding: 0;
  background: #fff;
  width: 100%;
  border-top: 1px solid #e2e4e8;
  margin-top: 4px;
}

.simple-tabs .radiotab:checked + .label + .panel {
  display: block;
}

@media (min-width: 600px) {
  .simple-tabs .panel {
    order: 99;
  }
}
